define("discourse/plugins/discourse-activity-pub/discourse/controllers/activity-pub-actor", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActor extends _controller.default {
    static #_ = dt7948.g(this.prototype, "composer", [_service.inject]);
    #composer = (dt7948.i(this, "composer"), void 0);
    static #_2 = dt7948.g(this.prototype, "site", [_service.inject]);
    #site = (dt7948.i(this, "site"), void 0);
    static #_3 = dt7948.g(this.prototype, "currentUser", [_service.inject]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    createTopic() {
      const props = {
        preferDraft: true
      };
      if (this.category) {
        props.category = this.category;
      }
      if (this.tags) {
        props.tags = this.tags;
      }
      this.composer.openNewTopic(props);
    }
    static #_4 = dt7948.n(this.prototype, "createTopic", [_object.action]);
  }
  _exports.default = ActivityPubActor;
});