define("discourse/plugins/discourse-activity-pub/discourse/routes/admin-plugins-activity-pub", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.service]);
    #router = (dt7948.i(this, "router"), void 0);
    afterModel(model, transition) {
      if (!this.site.activity_pub_enabled) {
        this.router.replaceWith("/404");
        return;
      }
      if (transition.targetName === "adminPlugins.activityPub.index") {
        this.router.transitionTo("adminPlugins.activityPub.actor");
      }
    }
  }
  _exports.default = AdminPluginsActivityPubRoute;
});