define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub-actor", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _tracking, _controller, _object, _computed, _service, _ajax, _ajaxError, _I18n, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubActor extends _controller.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.inject]);
    #router = (dt7948.i(this, "router"), void 0);
    static #_2 = dt7948.g(this.prototype, "actorShowController", [(0, _controller.inject)("adminPlugins.activityPub.actorShow")]);
    #actorShowController = (dt7948.i(this, "actorShowController"), void 0);
    static #_3 = dt7948.g(this.prototype, "order", [_tracking.tracked], function () {
      return "";
    });
    #order = (dt7948.i(this, "order"), void 0);
    static #_4 = dt7948.g(this.prototype, "asc", [_tracking.tracked], function () {
      return null;
    });
    #asc = (dt7948.i(this, "asc"), void 0);
    static #_5 = dt7948.g(this.prototype, "model_type", [_tracking.tracked], function () {
      return "category";
    });
    #model_type = (dt7948.i(this, "model_type"), void 0);
    loadMoreUrl = "";
    total = "";
    static #_6 = dt7948.g(this.prototype, "hasActors", [(0, _computed.notEmpty)("actors")]);
    #hasActors = (dt7948.i(this, "hasActors"), void 0);
    queryParams = ["model_type", "order", "asc"];
    get title() {
      return _I18n.default.t(`admin.discourse_activity_pub.actor.${this.model_type}.title`);
    }
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.actors.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.actors.pushObjects((response.actors || []).map(actor => {
            return _activityPubActor.default.create(actor);
          }));
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            total: response.meta.total,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_7 = dt7948.n(this.prototype, "loadMore", [_object.action]);
    addActor() {
      this.router.transitionTo("adminPlugins.activityPub.actorShow", _activityPubActor.newActor).then(() => {
        this.actorShowController.set("showForm", false);
      });
    }
    static #_8 = dt7948.n(this.prototype, "addActor", [_object.action]);
    editActor(actor) {
      this.router.transitionTo("adminPlugins.activityPub.actorShow", actor).then(() => {
        this.actorShowController.set("showForm", true);
      });
    }
    static #_9 = dt7948.n(this.prototype, "editActor", [_object.action]);
  }
  _exports.default = AdminPluginsActivityPubActor;
});