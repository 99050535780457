define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-authorization">
    <a
      href={{@authorization.actor_id}}
      class="activity-pub-authorization-link btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{d-icon "external-link-alt"}}
      {{@authorization.actor_id}}
    </a>
    <DButton
      @icon="times"
      @action={{action "remove"}}
      @title="user.discourse_activity_pub.remove_authorization_button.title"
      id="user_activity_pub_authorize_remove_authorization"
      class="activity-pub-authorize-remove-authorization"
    />
  </div>
  */
  {
    "id": "froer4uV",
    "block": "[[[10,0],[14,0,\"activity-pub-authorization\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,1,[\"actor_id\"]]],[14,0,\"activity-pub-authorization-link btn\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"external-link-alt\"],null]],[1,\"\\n    \"],[1,[30,1,[\"actor_id\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,1,\"user_activity_pub_authorize_remove_authorization\"],[24,0,\"activity-pub-authorize-remove-authorization\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[28,[37,2],[[30,0],\"remove\"],null],\"user.discourse_activity_pub.remove_authorization_button.title\"]],null],[1,\"\\n\"],[13]],[\"@authorization\"],false,[\"d-icon\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-authorization.hbs",
    "isStrictMode": false
  });
  class ActivityPubAuthorization extends _component2.default {
    static #_ = dt7948.g(this.prototype, "dialog", [_service.inject]);
    #dialog = (dt7948.i(this, "dialog"), void 0);
    remove() {
      const actorId = this.args.authorization.actor_id;
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("user.discourse_activity_pub.authorization.confirm_remove", {
          actor_id: actorId
        }),
        didConfirm: () => {
          this.args.remove(actorId);
        }
      });
    }
    static #_2 = dt7948.n(this.prototype, "remove", [_object.action]);
  }
  _exports.default = ActivityPubAuthorization;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubAuthorization);
});