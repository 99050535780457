define("discourse/plugins/discourse-activity-pub/discourse/controllers/preferences-activity-pub", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesActivityPubController extends _controller.default {
    static #_ = dt7948.g(this.prototype, "authorizations", [_tracking.tracked], function () {
      return null;
    });
    #authorizations = (dt7948.i(this, "authorizations"), void 0);
    static #_2 = dt7948.g(this.prototype, "hasAuthorizations", [(0, _computed.notEmpty)("authorizations")]);
    #hasAuthorizations = (dt7948.i(this, "hasAuthorizations"), void 0);
    removeAuthorization(actorId) {
      (0, _ajax.ajax)("/ap/auth/authorization.json", {
        data: {
          actor_id: actorId
        },
        type: "DELETE"
      }).then(() => {
        this.authorizations = this.authorizations.filter(a => {
          return a.actor_id !== actorId;
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = dt7948.n(this.prototype, "removeAuthorization", [_object.action]);
  }
  _exports.default = PreferencesActivityPubController;
});